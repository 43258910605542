<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        GFZ TERENO DOIs (Total Count: {{ dois.length }})
      </v-card-title>
      <v-card-text>
        <v-simple-table v-if="paginatedDois.length > 0">
          <thead>
            <tr>
              <th class="text-left" :style="{ width: '15%' }">DOI</th>
              <th class="text-left" :style="{ width: '50%' }">Title</th>
              <th class="text-left">Status</th>
              <th class="text-left">Last updated</th>
              <th class="text-left">Authors</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(doi, index) in paginatedDois" :key="index">
              <td class="text-left"><a :href="getCustomLandingPageUrl(doi.id)" target="_blank">{{ doi.id }}</a></td>
              <td class="text-left">{{ doi.attributes.titles[0].title }}</td>
              <td class="text-left">{{ doi.attributes.state }}</td>
              <td class="text-left">{{ new Date(doi.attributes.updated).toISOString().slice(0, 10) }}, {{ new Date(doi.attributes.updated).toLocaleTimeString('en-US', { hour12: true }) }}</td>
              <td class="text-left">
                <span v-for="(author, authorIndex) in doi.attributes.creators" :key="authorIndex">
                  {{ author.name }}
                  <span v-if="authorIndex < doi.attributes.creators.length - 1">; </span>
                </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-pagination
          v-if="totalPages > 1"
          v-model="currentPage"
          :length="totalPages"
          @input="updatePaginatedDois"
        ></v-pagination>
        <div v-if="error">{{ error }}</div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { components } from 'vue-component-library'
import axios from 'axios'
import loading from '@/shared/models/loading'

const { applicationState } = components

export default {
  name: 'ExternalDoiView',
  data () {
    return {
      dois: [],
      currentPage: 1,
      totalPages: 0,
      itemsPerPage: 12,
      error: null
    }
  },
  computed: {
    getCreatedBy (doi) {
      if (doi.attributes.creator && doi.attributes.creators.length > 0) {
        const names = doi.attributes.creator.map(creators => creators.name)
        return names.join(', ')
      }
      return ''
    },
    paginatedDois () {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage
      return this.dois.slice(startIndex, startIndex + this.itemsPerPage)
    }
  },
  async created () {
    try {
      await this.fetchDOIs()
    } catch (error) {
      console.error('Error fetching DOIs:', error)
      applicationState.addError('Error fetching DOIs')
      this.error = 'Error fetching DOIs'
    }
  },
  methods: {
    getCustomLandingPageUrl (doiId) {
      return `https://doi.tereno.net/landingpage/doi/${encodeURIComponent(`https://doi.tereno.net/api/dois/${encodeURIComponent(doiId)}`)}`
    },
    async fetchDOIs () {
      try {
        loading.startLoading()
        // Define the API endpoint URL with the specified prefix
        const apiUrl = 'https://api.datacite.org/dois?page[size]=10000&prefix=10.5880&query=tereno'
        const response = await axios.get(apiUrl)
        this.dois = response.data.data
        this.totalPages = Math.ceil(this.dois.length / this.itemsPerPage)
        loading.stopLoading()
      } catch (error) {
        console.error('Error fetching DOIs:', error)
        throw new Error('Error fetching DOIs')
      }
    },
    updatePaginatedDois (page) {
      this.currentPage = page
    }
  }
}
</script>
